import React, { useState, useEffect } from 'react';
import { TaskLayout, TaskBody, Title, TaskRow,TaskRowOfHead, TaskColText } from './Task.style.js';
import FW from '../../config/word.json';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/Layouts/Main/MainLayout.index.js';
import { getAllProInfoByAuthFlg } from "../../api/project";

function Home(props) {

    const navigate = useNavigate();

    const [tasks, settasks] = useState([]);
    // ログイン状態ではない場合、ログイン画面へ遷移する
    useEffect(() => {
        var userId = sessionStorage.getItem('userId');
        if (userId === undefined || userId === null || userId === "") {
            navigate('/', {});
        }
        // 雀活マスター承認待ち
        getAllProInfoByAuthFlg('2').then(res => {
            settasks(res.data);
        }).catch(res => {
        })
    }, []); // 空の依存配列を渡すことで、初回マウント時のみ実行される

    return (
        <MainLayout>
            <TaskLayout>
                <TaskBody>
                    <Title>{FW.F_W_HOME_ALL_TASK}</Title>
                            <TaskRowOfHead key='title'>
                            <TaskColText>Twitter表示名前</TaskColText>
                                <TaskColText>プロフィール名前</TaskColText>
                                <TaskColText>メールアドレス</TaskColText>
                            </TaskRowOfHead>
                    {tasks.map((item) => {
                        return (
                            <TaskRow key={item.user_id}>
                            <TaskColText>{item.twitter_name}</TaskColText>
                                <TaskColText>{item.user_name}</TaskColText>
                                <TaskColText>{item.email}</TaskColText>
                            </TaskRow>
                        );
                    })}
                </TaskBody>
            </TaskLayout>
        </MainLayout>
    );
}

export default Home;
