// SearchForm.js
import React from 'react';

const SearchForm = ({ onSubmit }) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const query = formData.get('query');
        onSubmit(query);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" name="query" placeholder="Search..." />
            <button type="submit">Search</button>
        </form>
    );
}

export default SearchForm;
