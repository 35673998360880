import { useState, useEffect } from 'react';

function useWindowSize(getIsMobile) {
    const [isMobile, setIsMobile] = useState(getIsMobile);
    useEffect(() => {
        const onResize = () => {
            setIsMobile(getIsMobile);
        };
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [getIsMobile]);

    return isMobile;
};

export default useWindowSize;
