import React, { useEffect,useState } from 'react';
import { FaTwitter } from 'react-icons/fa';
import { RejectListLayout, RejectListBody, GreyTitle, RejectListRow, RejectListRowKu,
    RejectListRowOfHead,ButtonContainer } from './RejectList.style.js';
import { GREEN,} from '../../styles/color';
import FW from '../../config/word.json';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/Layouts/Main/MainLayout.index.js';
import { GreenSmallButton,LoadingSpinner,LoadingOverlay, colorButtonStyle} from '../../styles/common.js';
import { getAllProInfoByAuthFlg,updateUserFromApproval } from "../../api/project";
import Modal from 'react-modal';


function RejectList(props) {

    const threespace='　　　';
    const onespace='　';
    const navigate = useNavigate();
    // ログイン状態ではない場合、ログイン画面へ遷移する
    
    const [tasks, setTasks] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentTask, setCurrentTask] = useState(Object);
    const [reloadEffect, setReloadEffect] = useState(false); // useEffectの再実行を制御するための状態
    const [loading, setLoading] = useState(false);
    // ユーザ種別（１：プロ、２：ゲスト(その他)）
    
    useEffect(() => {
        // このエフェクトは reloadEffect が変更されるたびに再実行されます
        var userId = sessionStorage.getItem('userId');
        if (userId === undefined || userId === null || userId === "") {
            navigate('/', {});
        }
        // 雀活マスター否認済
        getAllProInfoByAuthFlg('3').then(res => {
            setTasks(res.data);
        }).catch(res => {
        })
    }, [reloadEffect, navigate]); // reloadEffect を依存配列に追加

    const updateTask = async (task) => {
        var jUser = {};
        jUser.userId=task.user_id;
        jUser.email=task.email;
        jUser.userName=task.user_name;
        jUser.userFlg=selectedUserFlg[task.user_id];
        // 承認
        jUser.authFlg='1';
        updateUserFromApproval(jUser).then(res => {
            if (res.code === 200) {
                navigate('/RejectList', {});
            }
        }).catch(res => {
        })
        // ダミーの遅延
        await new Promise(resolve => setTimeout(resolve, 1000));
      };
      
      const openModal = (task) => {
        console.log(task);
        setCurrentTask(task);
        setModalIsOpen(true);
      };
    
      const closeModal = () => {
        setModalIsOpen(false);
      };

      const resetState = () => {
        setCurrentTask(Object);
        setReloadEffect(prev => !prev); // reloadEffectをトグルすることでuseEffectを再実行
        // 他の状態をリセットする場合はここに追加
      };
      // 復活
      const handleApprove = async () => {
        // ここに承認処理を追加
        closeModal();
        setLoading(true);
        await updateTask(currentTask);
        setLoading(false);
        resetState();
      };

    const TwitterIcon = () => {
        return <FaTwitter />;
    }

    const [selectedUserFlg, setSelectedUserFlg] = useState({});

    const handleGenderChange = (proId, value) => {
        setSelectedUserFlg(prevUserFlg => ({
            ...prevUserFlg,
            [proId]: value
        }));
    };

    return (
        <MainLayout>
            <RejectListLayout>
                <RejectListBody>
                    <GreyTitle>{FW.F_W_HOME_RejectList}</GreyTitle>
                            <RejectListRowOfHead >Twitter名前／ID</RejectListRowOfHead>
                            <RejectListRowOfHead >{threespace}雀活マスター表示名前</RejectListRowOfHead>
                            <RejectListRowOfHead >{threespace}{threespace}E-Mail</RejectListRowOfHead>
                            <RejectListRowOfHead >{threespace}プロ／ゲスト{threespace}{threespace}処理</RejectListRowOfHead>
                            {loading && (
                                <LoadingOverlay>
                                <LoadingSpinner />
                                </LoadingOverlay>
                             )}
                            {tasks.map((item) => {
                            return (
                                <RejectListBody key={item.user_id}>
                                    <RejectListRow><TwitterIcon />{item.twitter_name}／
                                    <a href={`https://x.com/${item.user_id}`} target="_blank" rel="noopener noreferrer">{item.user_id} </a>
                                    </RejectListRow>
                                    <RejectListRow>{threespace}{item.user_name}</RejectListRow>
                                    <RejectListRow>{threespace}{threespace}{item.email}</RejectListRow>
                                    <RejectListRow>{threespace}
                                        プロ：<input type="radio" name={`userFlg_${item.user_id}`} value="1" defaultChecked={true} 
                                        checked={selectedUserFlg[item.user_id] === '1'}
                                        onChange={(e) => handleGenderChange(item.user_id, e.target.value)}
                                        />
                                        {onespace}ゲスト：<input type="radio" name={`userFlg_${item.user_id}`}  value="2" 
                                        checked={selectedUserFlg[item.user_id] === '2'}
                                        onChange={(e) => handleGenderChange(item.user_id, e.target.value)} />
                                        <GreenSmallButton onClick={() => openModal(item)}>復活</GreenSmallButton>
                                        </RejectListRow>
                                            <RejectListRowKu></RejectListRowKu>
                                </RejectListBody>
                            );
                            })} 
                </RejectListBody>
            </RejectListLayout>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="雀活マスター承認">
                <div>
                    <p>◾️申込者：<br></br> ・{currentTask.twitter_name}<br></br>・{currentTask.user_name}<br></br>
                    ・{currentTask.email}<br></br>・{selectedUserFlg[currentTask.user_id]==="1"?"プロ":"ゲスト"}
                    <br></br><br></br>雀活マスターアプリの利用権限を付与します</p>
                </div>
                <ButtonContainer>
                    <button onClick={closeModal}>いいえ</button><button style={colorButtonStyle(GREEN)} onClick={handleApprove} >復活</button>
                </ButtonContainer>
            </Modal>
        </MainLayout>
    );
}

export default RejectList;