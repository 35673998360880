import React, { useState, useEffect } from 'react';
import { Title, FormLayout, Form } from './Login.style';
import { useNavigate } from 'react-router-dom';
import FW from '../../config/word.json';
import FS from '../../config/sentence.json';
import { login } from "../../api/project";


function Login(props) {

    // 変数定義
    const [userId, setUserId] = useState('');
    const [password, setPassword] = useState('');
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    // ログイン状態ではない場合、ログイン画面へ遷移する
    useEffect(() => {
        var userId = sessionStorage.getItem('userId');
        if (userId !== undefined && userId !== null && userId !== "") {
            navigate('/home', {});
        }
    });

    /**
     * ログインボタンを押下時の処理
     */
    function onFormSubmit(event) {
        event.preventDefault();
        setIsError(false);
        setErrorMessage('');
        if (userId.length < 5) {
            setIsError(true);
            setErrorMessage(FS.F_S_MSG1);
            return;
        }
        if (password.length < 6) {
            setIsError(true);
            setErrorMessage(FS.F_S_MSG2);
            return;
        }
        login({userId: userId, password: password}).then(res => {
            if (res.code === 200) {
                // ログイン状態を保持する
                sessionStorage.setItem('userId', userId);
                navigate('/home', {});
            }
        }).catch(res => {
            setIsError(true);
            setErrorMessage(res.message);
        })
    }

    /**
     * 登録画面に遷移
     */
    function turnRegister() {
        navigate('/register', {});
    }

    return (
        <FormLayout>
            <Title>{FW.F_W_LOGIN_T1}</Title>
            <Form onSubmit={onFormSubmit}>
                <div>
                    <input
                        type="text"
                        value={userId}
                        onChange={(e) => {
                            setUserId(e.target.value);
                            setIsError(false);
                            setErrorMessage('');
                        }}
                        maxLength={30}
                        placeholder={FW.F_W_SYS_N}
                        required
                        aria-label="userId"
                    />
                </div>
                <div>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setIsError(false);
                            setErrorMessage('');
                        }}
                        maxLength={30}
                        placeholder={FW.F_W_SYS_P}
                        required
                        aria-label="password"
                        autoComplete={"true"}
                    />
                </div>
                <div>
                    <input type="submit" value={FW.F_W_LOGIN_LOGIN} aria-label="submit" />
                </div>
                {/* <div>
                    <input type="button" onClick={turnRegister} value={FW.F_W_LOGIN_REGISTER} />
                </div> */}
                {isError && <p>{errorMessage}</p>}
            </Form>
        </FormLayout>
    );
}

export default Login;