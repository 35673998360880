import './App.css';
import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Task from './pages/Task/Task';
import Register from './pages/Register/Register'
import Search from './pages/Search/Search'
import ApprovalList from './pages/ApprovalList/ApprovalList'
import ApprovaledList from './pages/ApprovalList/ApprovaledList'
import RejectList from './pages/ApprovalList/RejectList'
import Menu from './pages/Menu/Menu.jsx'
import JoinInfo from './pages/JoinInfo/JoinInfo.jsx';
import Fans from './pages/Fans/Fans.jsx';
import FansCount from './pages/FansCount/FansCount.jsx';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Notice from './pages/Notice/Notice.js';

function App() {
  return (
    <div>
      <BrowserRouter basename=''>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path='/home' element={<Home />}></Route>
          <Route path='/task' element={<Task />}></Route>
          <Route path='/register' element={<Register />}></Route>
          <Route path='/search' element={<Search />}></Route>
          <Route path='/notice' element={<Notice />}></Route>
          <Route path='/approvallist' element={<ApprovalList />}></Route>
          <Route path='/approvaledlist' element={<ApprovaledList />}></Route>
          <Route path='/rejectedlist' element={<RejectList />}></Route>
          <Route path='/menu' element={<Menu />}></Route>
          <Route path='/joinInfo' element={<JoinInfo />}></Route>
          <Route path='/fans' element={<Fans />}></Route>
          <Route path='/fansCount' element={<FansCount />}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
