import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { LIGHT_BLUE, WHITE } from '../../../../../styles/color';

export const ScrollContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Layout = styled.div`
  position: fixed;
  bottom: 0;
  background-color: ${LIGHT_BLUE};
  width: 100%;
  color: white;
  display: table;
  table-layout: fixed;
`;

export const Content = styled.div`
  margin: 0px 0px 10px 0px;
`;

export const SubMenuItem = styled.div`
  display: table-cell;
  padding: 10px 5px;
  min-width: 50.5px;
  :nth-of-type(1) {
    border-left: transparent;
  }
  :nth-last-of-type(1) {
    border-right: transparent;
  }
  & > a {
    text-decoration: none;
    & > span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: ${WHITE};
      text-decoration: none;
      word-break: break-all;
      & > img {
        width: 30px;
        height: 30px;
      }
      & > svg {
        width: 30px;
        height: 30px;
      }
      & > span {
        font-size: 10px;
        margin-top: 2px !important;
        display: inline-block;
        text-align: center;
        line-height: 12px;
      }
    }
  }
`;

export const MenuLink = styled(Link)`
  color: white;
`;
