import React, { useEffect, useState } from 'react'
import { Table, TableSum, TableHeader, TableHeaderSum,TableHeaderSumForJM,TableRow, TitleCell } from './FansCount.style';
import MainLayout from '../../components/Layouts/Main/MainLayout.index';
import { getUserFans,getOshicalAndJankatsumasterUserCnt } from "../../api/project";

function FansCount() {
    const initList = [
        {
            user_name: 'ユーザー1',
            fansList: [
                {
                    fansName: 'ファン1',
                    fansmail: 'ファン1@example.com'
                },
                {
                    fansName: 'ファン2',
                    fansmail: 'ファン2@example.com'
                }
            ]
        },
        {
            user_name: 'ユーザー2',
            fansList: [
                {
                    fansName: 'ファン3',
                    fansmail: 'ファン3@example.com'
                },
                {
                    fansName: 'ファン4',
                    fansmail: 'ファン4@example.com'
                }
            ]
        }
    ]
    const [infoList, setInfoList] = useState(initList);
    const [oshicalUserCnt, setOshicalUserCnt] = useState('');
    const [jKMUserProCnt, setJKMUserProCnt] = useState('');
    const [jKMUserFansCnt, setJKMUserFansCnt] = useState('');

    useEffect(() => {
        const getInfos = async () => {
            try {

                const res1 = await getOshicalAndJankatsumasterUserCnt();
                console.log(res1);
                if (res1.code === 200 ) {
                    const sumInfo = res1.data;
                    setOshicalUserCnt(sumInfo.oshicalUserCnt);
                    setJKMUserProCnt(sumInfo.jKMUserProCnt);
                    setJKMUserFansCnt(sumInfo.jKMUserFansCnt);
                }

                const res = await getUserFans();
                console.log(res);
                if (res.code === 200 && res.data.length > 0) {
                    const fansInfo = res.data.map(item => {
                        const fansCount = item.fansList.length;
                        return {
                            user_name: item.user_name,
                            user_mail: item.user_mail,
                            fansCount: fansCount,
                        }
                    })
                    console.log(fansInfo);
                    setInfoList(fansInfo);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getInfos()
    }, [])


    return (

        <MainLayout>
            <TableSum>
                <thead>
                    <tr>
                        <TableHeaderSum>推しごとカレンダー利用者数↓</TableHeaderSum>
                        <TableHeaderSumForJM>雀活マスター利用プロ数↓</TableHeaderSumForJM>
                        <TableHeaderSumForJM>雀活マスター利用ファン数↓</TableHeaderSumForJM>
                    </tr>
                </thead>
                <tbody>
                        <TableRow>
                            <TitleCell>{oshicalUserCnt}</TitleCell>
                            <TitleCell>{jKMUserProCnt}</TitleCell>
                            <TitleCell>{jKMUserFansCnt}</TitleCell>
                        </TableRow>
                </tbody>
            </TableSum>
            <Table>
                <thead>
                    <tr>
                        <TableHeader>プロ／ゲスト</TableHeader>
                        <TableHeader>ファン数</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {infoList.map((item, index) => (
                        <TableRow key={`${index}`}>
                            <TitleCell>{item.user_name}</TitleCell>
                            <TitleCell>{item.fansCount}</TitleCell>
                        </TableRow>
                    ))}

                </tbody>
            </Table>
        </MainLayout>
    )
}

export default FansCount