import styled from '@emotion/styled';
import {WHITE, LIGHT_BLUE, BLACK } from '../../styles/color';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0 100px; /* 上部と下部にマージンを追加 */
   table-layout: fixed;
`;

export const TableHeader = styled.th`
  background-color: ${LIGHT_BLUE};
  color: ${WHITE};
  padding: 10px;
  text-align: left;
  border: 1px solid ${BLACK};
  font-size: 12px;
  text-align: center;
`;

export const TableCell = styled.td`
  padding: 10px;
  border: 1px solid ${BLACK};
`;

export const TableRow = styled.tr`
  // &:nth-of-type(even) {
  //   background-color: ${BLACK}10; // 10% opacity
  // }
`;

export const TitleCell = styled(TableCell)`
  // font-weight: bold;
  word-break: break-all; 
  word-wrap: break-word;
`;