import React, { memo } from 'react';
import useWindowSize from '../../../../hooks/useWindowSize';
import MenuBar from './MenuBar/MainMenuBar.index';
import { useLocation } from 'react-router-dom';

function Footer() {
    const getIsMobile = () => window.innerWidth < 601;
    const isMobile = useWindowSize(getIsMobile);
    let showflg=true;
    const url = useLocation().pathname;
    // お知らせ画面である場合、メニューバーが不要
    if(url.includes('notice')){
        showflg=false;
    }

    return <>{isMobile && showflg ? <MenuBar /> : <></>}</>;
};

export default memo(Footer);
