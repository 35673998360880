import styled from '@emotion/styled';
import { LIGHT_BLUE, RED, WHITE, BLUE_HOVER, LIGHT_WHITE, LIGHT_BLACK } from '../../styles/color';
import { SMART_SIZE } from '../../styles/common';

export const Title = styled.h2`
  color: ${LIGHT_BLUE};
`;
export const FormLayout = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  border: 1px solid ${LIGHT_BLUE};
  border-radius: 10px;
  width: 530px;
  height: 480px;
  text-align:center;
  ${SMART_SIZE} {
    width: 95%;
  }
`;
export const Form = styled.form`
  margin-top: 50px;
  position: relative;

  > div {
    margin-bottom: 30px;
  }

  > div > input {
    max-width: 330px;
    width: 100%;
    height: 30px;
    border: 1px solid ${LIGHT_BLUE};
    border-radius: 7px;
    font-size: 18px;
    padding: 5px 18px 5px 18px;

    ::placeholder {
      color: ${LIGHT_WHITE};
      font-size: 14px;
    }

    ${SMART_SIZE} {
      width: 74%;
    }
  }

  > div > input[type='submit'] {
    margin-top: 12px;
    max-width: 330px;
    height: 50px;
    color: ${WHITE};
    border: none;
    background: ${LIGHT_BLUE} 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 8px ${LIGHT_BLACK};
    border-radius: 5px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;

    &:hover {
      background: ${BLUE_HOVER} 0% 0% no-repeat padding-box;
    }
  }

  > div > input[type='button'] {
    max-width: 330px;
    height: 50px;
    color: ${WHITE};
    border: none;
    background: ${LIGHT_BLUE} 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 8px ${LIGHT_BLACK};
    border-radius: 5px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;

    &:hover {
      background: ${BLUE_HOVER} 0% 0% no-repeat padding-box;
    }
  }

  > p {
    max-width: 330px;
    text-align: center;
    margin: auto;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.7px;
    font-weight: 700;
    height: 60px;
    color: ${RED};
  }
`;