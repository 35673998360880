import styled from '@emotion/styled';
import { WHITE, APP_TEMA,LIGHT_GRAY } from '../../../../../styles/color';

export const Layout = styled.div`
 grid-area: header;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${LIGHT_GRAY};
  background-color: ${APP_TEMA};
  height: 80px;
  > h2 {
    color: ${WHITE};
  }
`;
