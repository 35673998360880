import React, { useEffect, useState } from 'react';
import { Table, TableHeader, TableCell, TableRow, TitleCell } from './Fans.style';
import MainLayout from '../../components/Layouts/Main/MainLayout.index';
import { getUserFans } from "../../api/project";

const Fans = () => {
    const [infoList, setInfoList] = useState([]);

    useEffect(() => {
        const getInfos = async () => {
            try {
                const res = await getUserFans();
                console.log(res);
                if (res.code === 200 && res.data.length > 0) {
                    setInfoList(res.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getInfos();
    }, []);

    return (
        <MainLayout>
            <Table>
                <thead>
                    <tr>
                        <TableHeader>プロ／ゲスト</TableHeader>
                        <TableHeader>ファン名</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {infoList.map((item, index) => (
                        <React.Fragment key={index}>
                            <TableRow>
                                <TableCell rowSpan={item.fansList.length + 1}>
                                    {item.user_name}
                                </TableCell>
                            </TableRow>
                            {item.fansList.map((fans, fansIndex) => (
                                <TableRow key={`${index}-${fansIndex}`}>
                                    <TitleCell>{fans.fanName}</TitleCell>
                                </TableRow>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </Table>
        </MainLayout>
    );
};

export default Fans;