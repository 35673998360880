import React, { useEffect } from 'react';
import { Table, TableHeader, TableCell, TableRow, TitleCell } from './JoinInfo.style';
import MainLayout from '../../components/Layouts/Main/MainLayout.index';
import {getDaikaiParticipants} from "../../api/project";


const JoinInfo = () => {

  const [infoList, setInfoList] = React.useState([]);
  useEffect( () => {
    const getInfos = async () => {
      const res=await getDaikaiParticipants()
      console.log(res);
      if(res.data.length>0){
        const infoList=res.data
        setInfoList(infoList);
        }
    }
    getInfos();
    
  }, []);

  return (
    <MainLayout>
      <Table>
        <thead>
          <tr>
            <TableHeader>大会情報</TableHeader>
            <TableHeader>申込者</TableHeader>
            <TableHeader>同卓希望のプロ</TableHeader>
            <TableHeader>メモ</TableHeader>
          </tr>
        </thead>
        <tbody>
          {infoList.map((item,index) => (
            <>
              <TableRow key={index}>
                <TableCell rowSpan={item.joinList.length + 1}>
                  {item.daikai_title}
                </TableCell>
              </TableRow>
              {item.joinList.map((joinInfo) => (
                <TableRow key={joinInfo.email}>
                  <TitleCell >{joinInfo.user_name}</TitleCell>
                  <TitleCell >{joinInfo.want_same_table_pro_name}</TitleCell>
                  <TitleCell >{joinInfo.daikai_memo}</TitleCell>
                </TableRow>
              ))}
            </>
          ))}
        </tbody>
      </Table>
    </MainLayout>

  );
};

export default JoinInfo;