export const tasks = [
    { key: 0, name: '花子０１' ,dantai:'池袋雀荘０１'},
    { key: 1, name: '花子０2'  ,dantai:'池袋雀荘０2'},
    { key: 2, name: '花子０2'  ,dantai:'　　　　　　'},
    { key: 3, name: '花子０3'  ,dantai:'池袋雀荘０3'},
    { key: 4, name: '花子04'  ,dantai:'池袋雀荘０4'},
    { key: 5, name: '花子０5'  ,dantai:'池袋雀荘０5'},
    { key: 6, name: '花子０6'  ,dantai:'　　　　　　'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'},
    { key: 7, name: '花子０7'  ,dantai:'池袋雀荘０6'}
]
