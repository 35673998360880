import request from '../utils/request'

export function login(query) {
    return request({
        url: '/login',
        method: 'post',
        data: query
    })
}

export function updateUserFromApproval(query) {
    return request({
        url: '/updateUserFromApproval',
        method: 'post',
        data: query
    })
}

export function register(data) {
    return request({
        url: '/register',
        method: 'post',
        data: data
    })
}

export function getUser(userId) {
    return request({
        url: '/getUser/'+userId,
        method: 'get'
    })
}

export function getAllProInfoByAuthFlg(authFlg) {
    return request({
        url: '/getAllProInfoByAuthFlg/'+authFlg,
        method: 'get'
    })
}

export function verify(token) {
    return request({
        url: '/verify/'+token,
        method: 'get'
    })
}

export function getDaikaiParticipants() {
    return request({
        url: '/getDaikaiParticipants',
        method: 'get'
    })
}
export function getUserFans() {
    return request({
        url: '/getUserFans',
        method: 'get'
    })
}
// 推しごとカレンダー、雀活マスター利用人数を統計
export function getOshicalAndJankatsumasterUserCnt() {
    return request({
        url: '/getOshicalAndJankatsumasterUserCnt',
        method: 'get'
    })
}
