import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { LIGHT_BLUE, WHITE } from '../../styles/color';
export const MenuLink = styled(Link)`
    margin-top: 20px;
    text-decoration: none;
    color: ${WHITE};
    font-size: 20px;
    background-color: ${LIGHT_BLUE};
    border-radius: 10px;
    padding: 10px;
    width: 220px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    // text-align: left;
`;

export const MenuList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30%;
`;

