// common.js
import { LIGHT_BLUE, BLUE_HOVER, BLACK ,RED,GREEN} from '../styles/color';
import styled from '@emotion/styled';
import { keyframes } from 'styled-components';
export const SMART_SIZE = '@media screen and (max-width: 600px)';
export const SMALL_SIZE = '@media screen and (max-width: 250px)';
export const SmallButton = styled.button`
  height: 22px;
  color: white;
  border: none;
  background: ${LIGHT_BLUE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 8px ${BLACK}00026;
  border-radius: 5px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background: ${BLUE_HOVER} 0% 0% no-repeat padding-box;
  }
}`;

export const GreenSmallButton = styled.button`
  height: 22px;
  color: white;
  border: none;
  background: ${GREEN} 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 8px ${BLACK}00026;
  border-radius: 5px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background: ${BLUE_HOVER} 0% 0% no-repeat padding-box;
  }
}`;

export const SmallButtonForReject = styled.button`
  height: 22px;
  color: white;
  border: none;
  background: ${RED} 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 8px ${BLACK}00026;
  border-radius: 5px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background: ${RED} 0% 0% no-repeat padding-box;
  }
}`;


export const MidButton = styled.button`
  height: 22px;
  color: white;
  border: none;
  background: ${LIGHT_BLUE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 8px ${BLACK}00026;
  border-radius: 5px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  display: block;
  margin-left: 10px;
  margin-right: 10px;

  &:hover {
    background: ${BLUE_HOVER} 0% 0% no-repeat padding-box;
  }
}`;

export const ErrorMsg = styled.p`
 position: relative;
 top:15px
`;

export const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div`
border: 4px solid rgba(0, 0, 0, 0.1);
width: 36px;
height: 36px;
border-radius: 50%;
border-left-color: #09f;
animation: ${spin} 1s linear infinite;
`;

export const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    width: '300px',
    textAlign: 'center'
  },
};

export const colorButtonStyle  = (backgroundColor) => {
  return {
    backgroundColor: backgroundColor,  // 背景色を赤に設定
    color: 'white',           // 文字色を白色に設定
    padding: '10px 20px',     // パディングを設定
    border: 'none',           // ボーダーをなしに設定
    borderRadius: '4px',      // ボーダーの角を丸くする
    cursor: 'pointer',        // カーソルをポインターに設定
  }
};

export const smalButtonStyle  = (backgroundColor) => {
  return {
    backgroundColor: backgroundColor,
  }
};