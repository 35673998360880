import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';

import { SideBarItems } from '../../MainSideBarItems';
import {
    SubMenuItem,
    MenuLink,
    ScrollContainer,
    Layout,
    Content,
} from './MainMenuBar.style';

const CustomMenuLink = ({ title, to, icon, clickedIcon }) => {
    const url = useLocation().pathname;

    // ログアウトのボタンを押下時にセッションストレージをクリアする
    const onClickMenuItem = (toPage) => () => {
        if (toPage === '/') {
            // ログアウトをクリックすると、セッションストレージをクリアする
            sessionStorage.clear();
        }
    };

    const active = url.includes(to) && to !== '/' ? 'active' : '';

    return (
        <SubMenuItem>
            <MenuLink to={to}>
                <span onClick={onClickMenuItem(to)} aria-hidden="true">
                    {active ? icon : clickedIcon}
                    <span>{title}</span>
                </span>
            </MenuLink>
        </SubMenuItem>
    );
};

/**
 * フッターのメニューのコンポーネント
 * @returns フッターのメニューのコンポーネント
 */
function MenuBar() {
    return (
        <>
            <Layout>
                <Content>
                    <ScrollContainer>
                        {SideBarItems.map((item) => {
                            return (
                                <React.Fragment key={item.title}>
                                    <CustomMenuLink
                                        title={item.title}
                                        to={item.path}
                                        icon={item.icon}
                                        clickedIcon={item.clickedIcon}
                                    />
                                </React.Fragment>
                            );
                        })}
                    </ScrollContainer>
                </Content>
            </Layout>
        </>
    );
};

export default memo(MenuBar);
