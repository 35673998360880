import styled from '@emotion/styled';
import { Row } from 'react-bootstrap';
import { LIGHT_BLUE, BLACK, RED, WHITE, BLUE_HOVER, LIGHT_WHITE } from '../../styles/color';
import { SMALL_SIZE, SMART_SIZE } from '../../styles/common';

export const Title = styled.h2`
  color: ${LIGHT_BLUE};
  margin-bottom: -20px;
  ${SMART_SIZE} {
    margin-top: 5px;
    text-align: center;
  }
`;
export const RegisterLayout = styled.div`
  max-width: 550px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
  ${SMART_SIZE} {
    width: 85%;
  }
`;
export const RRow = styled(Row)`
  text-align: left;
  min-height: 62px;
  width: 100%;
`;
export const ColTitle = styled.p`
  width: 180px;
  text-align: left;
  line-height: 22px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.9px;
  color: ${BLACK};
  display: inline-block;
`;
export const Label = styled.label`
  margin-left: 5px;
  margin-right: 20px;
  text-align: right;
  line-height: 25px;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.9px;
  color: ${BLACK};
  display: inline-block;
`;
export const RHeader = styled.div`
  display: flex;
  cursor: pointer;
  width: max-content;
`;
export const BackIcon = styled.img`
  margin-top: 10px;
  width: 15px;
  height: 15px;
`;
export const BackText = styled.p`
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 20px;
  font-weight: bold;
`;
export const Form = styled.form`
  margin-top: 50px;
  position: relative;
  ${SMART_SIZE} {
    margin-top: 5px;
  }

  > div > span > input {
    max-width: 330px;
    width: 100%;
    height: 30px;
    border: 1px solid ${LIGHT_BLUE};
    border-radius: 7px;
    font-size: 18px;
    padding: 5px 18px 5px 18px;
    ::placeholder {
      color: ${LIGHT_WHITE};
      font-size: 14px;
    }
    ${SMART_SIZE} {
      box-sizing: border-box;
      height: 40px;
    }
  }

  > div > input[type='submit'] {
    margin-top: 35px;
    width: 50%;
    height: 50px;
    color: ${WHITE};
    border: none;
    background: ${LIGHT_BLUE} 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 8px ${BLACK}00026;
    border-radius: 5px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &:hover {
      background: ${BLUE_HOVER} 0% 0% no-repeat padding-box;
    }
    ${SMART_SIZE} {
      width: 200px;
    }
    ${SMALL_SIZE} {
      width: 100%;
    }
  }

  > div > input[type='button'] {
    margin-top: 35px;
    width: 50%;
    height: 50px;
    color: ${WHITE};
    border: none;
    background: ${LIGHT_BLUE} 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 8px ${BLACK}00026;
    border-radius: 5px;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &:hover {
      background: ${BLUE_HOVER} 0% 0% no-repeat padding-box;
    }
    ${SMART_SIZE} {
      width: 200px;
    }
    ${SMALL_SIZE} {
      width: 100%;
    }
  }

  > div > span > input[type='radio'] {
    vertical-align: middle;
    width: 20px;
    height: 25px;
  }

  > div > span {
    text-align: center;
    ${SMART_SIZE} {
      display: block;
    }
  }

  > p {
    max-width: 330px;
    text-align: center;
    margin: auto;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.7px;
    font-weight: 700;
    height: 60px;
    color: ${RED};
  }
`;

export const ErrorMsg = styled.p`
 position: relative;
 top:15px
`;