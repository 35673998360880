import React, { useState, useEffect } from 'react';
import { verify } from '../../api/project';
import {
    NoticeBody,
    Title,
    NoticeLayout,
    NoticeRow,
    NoticeColText,
} from './Notice.style';
import FW from '../../config/word.json';
import MainLayout from '../../components/Layouts/Main/MainLayout.index';

function Notice(props) {

    const [noticeData, setNoticeData] = useState({});

    // ログイン状態ではない場合、ログイン画面へ遷移する
    useEffect(() => {
        setNoticeData("");
        fetchData();
        return;
    }, []);

    function fetchData() {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token === undefined || token === null || token === "") {
            setNoticeData("URL不正、利用できないため、新しいURLを利用してください。");
          return;
        }
        verify(token).then(res => {
          if (res.code === 200) {
            setNoticeData("本人認証成功、審査完了までお待ちください。");
          } else {
            setNoticeData("無効なURLになりました、アプリから再度認証手続を行ってください。");
          }
      }).catch(res => {
        setNoticeData("無効なURLになりました、アプリから再度認証手続を行ってください。");
      })
    }

    return (
        <MainLayout>
            <NoticeLayout>
                    <NoticeBody>
                        <Title>{FW.F_W_Notice_T}</Title>
                        <NoticeRow>
                            <NoticeColText>
                            {Object.values(noticeData).map(value => (
                            <h key={value}>{value}</h>
                            ))}</NoticeColText>
                        </NoticeRow>
                    </NoticeBody>
            </NoticeLayout>
        </MainLayout>
    );
}

export default Notice;