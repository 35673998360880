import React, { useState } from 'react';
import {
    BackIcon,
    BackText,
    ColTitle,
    ErrorMsg,
    Form,
    Label,
    RegisterLayout,
    RHeader,
    RRow,
    Title
} from './Register.style';
import { useNavigate } from 'react-router-dom';
import FW from '../../config/word.json';
import FS from '../../config/sentence.json';
import useWindowSize from '../../hooks/useWindowSize';
import { register } from "../../api/project";

function REGISTER(props) {
    const getIsMobile = () => window.innerWidth < 601;
    const isMobile = useWindowSize(getIsMobile);
    // 変数定義
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [gender, setGender] = useState('male');
    const [telNo, setTelNo] = useState('');
    const [email, setEmail] = useState('');
    const [birthday, setBirthday] = useState('');
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    /**
     * ログインボタンを押下時の処理
     */
    function onFormSubmit(event) {
        event.preventDefault();
        setIsError(false);
        setErrorMessage('');
        if (!validate()) {
            return;
        }
        const userData = {};
        userData.userId = userId;
        userData.userName = userName;
        userData.password = password;
        userData.gender = gender;
        userData.birthday = birthday;
        userData.tel = telNo;
        userData.email = email;
        register(userData).then(res => {
            if (res.code === 200) {
                sessionStorage.setItem('userId', userId);
                navigate('/home', {});
            }
        }).catch(res => {
            setIsError(true);
            setErrorMessage(res.message);
        })
    }

    /**
     * ログイン画面に戻り
     */
    function revert() {
        navigate('/', {});
    }

    /**
     * 検証
     */
    function validate() {
        if (userId.length < 5) {
            setIsError(true);
            setErrorMessage(FS.F_S_MSG1);
            return false;
        }
        if (password.length < 6) {
            setIsError(true);
            setErrorMessage(FS.F_S_MSG2);
            return false;
        }
        if (password !== passwordConfirm) {
            setIsError(true);
            setErrorMessage(FS.F_S_MSG3);
            return false;
        }
        if (new Date(birthday) > new Date()) {
            setIsError(true);
            setErrorMessage(FS.F_S_MSG5);
            return false;
        }
        if (email) {
            var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!regex.test(email)) {
                setIsError(true);
                setErrorMessage(FS.F_S_MSG6);
                return false;
            }
        }
        return true;
    }

    return (
        <RegisterLayout>
            {isMobile ?
                <RHeader onClick={revert}>
                    <BackIcon src="/images/icons/angle-left.svg" alt="" />
                    <BackText>{FW.F_W_TO_LOGIN}</BackText>
                </RHeader> : <></>}
            <Title>{FW.F_W_REGISTER_T}</Title>
            <Form onSubmit={onFormSubmit}>
                <RRow>
                    <ColTitle>{FW.F_W_SYS_N}</ColTitle>
                    <span>
                        <input
                            type="text"
                            value={userId}
                            onChange={(e) => {
                                setUserId(e.target.value);
                                setIsError(false);
                                setErrorMessage('');
                            }}
                            maxLength={30}
                            placeholder={FS.F_S_P_USERID}
                            required
                            aria-label="userId"
                        />
                    </span>
                </RRow>
                <RRow>
                    <ColTitle>{FW.F_W_SYS_UN}</ColTitle>
                    <span>
                        <input
                            type="text"
                            value={userName}
                            onChange={(e) => {
                                setUserName(e.target.value);
                                setIsError(false);
                                setErrorMessage('');
                            }}
                            maxLength={20}
                            placeholder={FW.F_W_SYS_UN}
                            required
                            aria-label="userName"
                        />
                    </span>
                </RRow>
                <RRow>
                    <ColTitle>{FW.F_W_SYS_P}</ColTitle>
                    <span>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                                setIsError(false);
                                setErrorMessage('');
                            }}
                            maxLength={30}
                            placeholder={FS.F_S_P_PASSWORD}
                            required
                            aria-label="password"
                            autoComplete={"true"}
                        />
                    </span>
                </RRow>
                <RRow>
                    <ColTitle>{FW.F_W_SYS_P_C}</ColTitle>
                    <span>
                        <input
                            type="password"
                            value={passwordConfirm}
                            onChange={(e) => {
                                setPasswordConfirm(e.target.value);
                                setIsError(false);
                                setErrorMessage('');
                            }}
                            maxLength={30}
                            placeholder={FS.F_S_P_PASSWORD}
                            required
                            aria-label="passwordConfirm"
                            autoComplete={"true"}
                        />
                    </span>
                </RRow>
                <RRow>
                    <ColTitle>{FW.F_W_SYS_G}</ColTitle>
                    <span className={"gender-span"}>
                        <input type="radio" name="gender" id="male" value="male" defaultChecked={true} onClick={(e) => {
                            setGender("male");
                            setIsError(false);
                            setErrorMessage('');
                        }} />
                        <Label htmlFor="male">{FW.F_W_SYS_G_M}</Label>
                        <input type="radio" name="gender" id="female" value="female" onClick={(e) => {
                            setGender("female");
                            setIsError(false);
                            setErrorMessage('');
                        }} />
                        <Label htmlFor="female">{FW.F_W_SYS_G_F}</Label>
                    </span>
                </RRow>
                <RRow>
                    <ColTitle>{FW.F_W_SYS_B}</ColTitle>
                    <span>
                        <input
                            type="date"
                            value={birthday}
                            required
                            max={new Date()}
                            onChange={(e) => {
                                setBirthday(e.target.value);
                                setIsError(false);
                                setErrorMessage('');
                            }}
                        />
                    </span>
                </RRow>
                <RRow>
                    <ColTitle>{FW.F_W_SYS_TEL}</ColTitle>
                    <span>
                        <input
                            type="tel"
                            value={telNo}
                            onChange={(e) => {
                                setTelNo(e.target.value);
                                setIsError(false);
                                setErrorMessage('');
                            }}
                            placeholder={FW.F_W_SYS_TEL}
                            required
                        />
                    </span>
                </RRow>
                <RRow>
                    <ColTitle>{FW.F_W_SYS_E}</ColTitle>
                    <span>
                        <input
                            type="text"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                setIsError(false);
                                setErrorMessage('');
                            }}
                            placeholder={FW.F_W_SYS_E}
                            required
                        />
                    </span>
                </RRow>
                <div className={"div-foot"}>
                    <input type="submit" value={FW.F_W_REGISTER_REGISTER} aria-label="submit" />
                    {isMobile ? <></> : <input type="button" value={FW.F_W_TO_LOGIN} onClick={revert} />}
                </div>
                {isError && <ErrorMsg>{errorMessage}</ErrorMsg>}
            </Form>
        </RegisterLayout>
    );
}

export default REGISTER;