import axios from 'axios'
import {tansParams} from '../utils/tools'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
    baseURL: 'https://jankatsu.ztt.co.jp/v3/api',
    timeout: 30000
})

service.interceptors.request.use(config => {
    if (config.method === 'get' && config.params) {
        let url = config.url + '?' + tansParams(config.params);
        url = url.slice(0, -1);
        // config.params = {};
        config.url = url;
    }
    return config
}, error => {
    let { message } = error;
    if (message === "Network Error" ||message.includes("timeout")) {
      message = "システムエラー、システム管理者に連絡してください。";
    }
    error.message = message;
    return Promise.reject(error)
})

service.interceptors.response.use(res => {
        const code = res.data.code || 200;
        // エラー情報
        var msg = res.data.msg;
        if (code !== 200) {
            return Promise.reject(new Error(msg))
        } else {
            return Promise.resolve(res.data)
        }
    },
    error => {
        let { message } = error;
        if (message === "Network Error" ||message.includes("timeout")) {
          message = "システムエラー、システム管理者に連絡してください。";
        }
        error.message = message;
        return Promise.reject(error)
    }
)

export default service
