import React from 'react';
import FW from '../../../config/word.json'

export let SideBarItems = [
    {
        title: FW.F_W_SYS_HOME,
        path: '/home',
        label: 'home',
        icon: <img src="/images/icons/home_active.svg" alt="" />,
        clickedIcon: <img src="/images/icons/home.svg" alt="" />,
    },
    // {
    //     title: FW.F_W_HOME_SEARCH,
    //     path: '/search',
    //     label: 'search',
    //     icon: <img src="/images/icons/card-active.svg" alt="" />,
    //     clickedIcon: <img src="/images/icons/card.svg" alt="" />,
    // },

    //  メニュー
    {
        title: FW.F_W_HOME_MENU,
        path: '/menu',
        label: 'menu',
        icon: <img src="/images/icons/menu.svg" alt="" />,
        clickedIcon: <img src="/images/icons/menu_active.svg" alt="" />,
    },
    //　参会情報
    {
        title: FW.F_W_HOME_JOIN,
        path: '/joinInfo',
        label: 'joinInfo',
        icon: <img src="/images/icons/user.svg" alt="" />,
        clickedIcon: <img src="/images/icons/user_active.svg" alt="" />,
    },

    //　ファン情報
    {
        title: FW.F_W_HOME_FAN,
        path: '/fans',
        label: 'fans',
        icon: <img src="/images/icons/fans.svg" alt="" />,
        clickedIcon: <img src="/images/icons/fans_active.svg" alt="" />,
    },

    //　ファン統計
    {
        title: FW.F_W_HOME_FANCOUNT,
        path: '/fansCount',
        label: 'fanscount',
        icon: <img src="/images/icons/count.svg" alt="" />,
        clickedIcon: <img src="/images/icons/count_active.svg" alt="" />,
    },

    //　ログアウト
    {
        title: FW.F_W_SYS_LOGOUT,
        path: '/',
        label: 'logout',
        icon: <img src="/images/icons/log-out-active.svg" alt="" />,
        clickedIcon: <img src="/images/icons/log-out.svg" alt="" />,
    },
];
