import styled from '@emotion/styled';
import { Row } from 'react-bootstrap';
import { LIGHT_BLUE, BLUE_HOVER, BLACK, GREY } from '../../styles/color';
import { SMART_SIZE } from '../../styles/common';

export const Title = styled.h2`
  color: ${LIGHT_BLUE};
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
`;
export const HomeLayout = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 10px;
  width: 100%;
  text-align:center;
  > p {
    margin-top: 50px;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.7px;
    font-weight: 700;
    color: red;
  }
`;
export const HomeBody = styled.div`
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 100px;
`;
export const HomeRow = styled(Row)`
  padding-top: 20px;
  display: flex;
  align-items:center;
  margin: 0px 20px;
  border-bottom: 1px solid ${GREY};
`;

export const HomeColTitle = styled.p`
  font-weight: 700;
  margin-left: 10px;
  text-align: left;
  line-height: 22px;
  min-width: 150px;
  font-size: 16px;
  letter-spacing: 0.9px;
  color: ${BLACK};
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const HomeColText = styled.p`
  box-sizing: border-box;
  text-align: left;
  padding-left: 50px;
  line-height: 22px;
  font-size: 18px;
  letter-spacing: 0.9px;
  color: ${BLACK};
  margin-top: 10px;
  margin-bottom: 5px;
  word-break:break-all;
  ${SMART_SIZE} {
    padding-left: 0px;
  }
`;

export const HomeButton = styled.button`
  min-width: 80px;
  height: 35px;
  color: white;
  border: none;
  background: ${LIGHT_BLUE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 8px ${BLACK}00026;
  border-radius: 5px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:hover {
    background: ${BLUE_HOVER} 0% 0% no-repeat padding-box;
  }
}`;
