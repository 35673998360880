import React from 'react';
import Header from './Header/Header.index';
import Footer from './Footer/MainFooter.index';
import { CommonBlockBox, CommonLayout, MyPageLayoutWrapper } from './MainLayout.style';

const MainLayout = ({ children }) => {
    return (
        <>
            <MyPageLayoutWrapper>
                <Header />
                <CommonLayout>
                    <CommonBlockBox>{children}</CommonBlockBox>
                </CommonLayout>
                <Footer />
            </MyPageLayoutWrapper>
        </>
    );
};

export default MainLayout;
