import React, { memo } from 'react';

import useWindowSize from '../../../../hooks/useWindowSize';
import PCHeader from './PCHeader/PCHeader.index';
import MobileHeader from './MobileHeader/MobileHeader.index';

const Header = () => {
  const getIsMobile = () => window.innerWidth < 601;
  const isMobile = useWindowSize(getIsMobile);
  return <>{isMobile ? <MobileHeader /> : <PCHeader />}</>;
};

export default memo(Header);
