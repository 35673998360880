import styled from '@emotion/styled';
import { SMART_SIZE } from '../../../styles/common';

// export const MyPageLayoutWrapper = styled.div`
//   height: 100%;
//   position: relative;
// `;
export const MyPageLayoutWrapper = styled.div`
   display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 
    "header"
    "main"
    "footer";
  height: 100vh;
`;
export const CommonLayout = styled.div`
 grid-area: main;
  overflow-y: auto;
  padding-left: 40px;
  padding-right: 40px;
  ${SMART_SIZE} {
    padding-left: 4%;
    padding-right: 4%;
  }
`;

// export const CommonLayout = styled.div`
//  grid-area: main;
//   overflow-y: auto;
//   margin-left: 40px;
//   margin-right: 40px;
//   ${SMART_SIZE} {
//     margin-left: 4%;
//     margin-right: 4%;
//   }
// `;

export const CommonBlockBox = styled.div`
  text-align: center;
`;
