// COlOR
export const WHITE = '#ffffff';
export const LIGHT_WHITE = '#b5b5b6';
export const BLACK = '#000000';
export const LIGHT_BLACK = '#00000026';
export const GREY = '#888787';
export const RED = '#e33737';
export const GREEN = '#008000';
export const LIGHT_GREEN = '#9BCF97';
// export const LIGHT_BLUE = '#428BCA';
export const LIGHT_BLUE = '#0095FF';
export const APP_TEMA = '#0095FF';
export const BLUE_HOVER = '#007ACC';
export const LIGHT_GRAY = '#c4c4c4';
// 0xFFECE9DE
export const BEI_JYO='#ECE9DE'
export const NAVY_BLUE='#000080'
export const oshical_green='#00C6BB'
