import React from 'react'
import MainLayout from '../../components/Layouts/Main/MainLayout.index';
import FW from '../../config/word.json'

import { MenuList, MenuLink } from './Menu.style'

export default function Menu() {

    const menuItems = [
        {
            title: FW.F_W_HOME_ApprovalList,
            path: '/approvallist',
        },
        {
            title: FW.F_W_HOME_ApprovaledList,
            path: '/approvaledlist',
        },
        {
            title: FW.F_W_HOME_RejectList,
            path: '/rejectedlist',
        }
    ];
    return (
        <MainLayout>
            <MenuList>
                {menuItems.map((item, index) =>
                    <MenuLink to={item.path} key={index}>
                        {item.title}
                    </MenuLink>)}
            </MenuList>


        </MainLayout>
    )
}

