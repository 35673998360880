import React, { useState, useEffect } from 'react';
import {
    HomeBody,
    Title,
    HomeLayout,
    HomeRow,
    HomeColTitle,
    HomeColText,
    HomeButton
} from './Home.style';
import FW from '../../config/word.json';
import FS from '../../config/sentence.json';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/Layouts/Main/MainLayout.index';
import {getUser} from "../../api/project";

function Home(props) {

    const navigate = useNavigate();
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isHomeLoaded, setIsHomeLoaded] = useState(false);
    const [userData, setUserData] = useState({});

    // 性別変換
    function genderVal(gender) {
        switch (gender) {
            case "male":
                return FW.F_W_GEN_M;
            case "female":
                return FW.F_W_GEN_F;
            default:
                return FW.F_W_GEN_S;
        }
    }

    // 日付変換
    function formatDate(date) {
        if (date) {
            var year = date.substr(0, 4);
            var month = date.substr(5, 2);
            var day = date.substr(8, 2);
            return year + "年" + month + "月" + day + "日";
        }
    }

    // ログイン状態ではない場合、ログイン画面へ遷移する
    useEffect(() => {
        var userId = sessionStorage.getItem('userId');
        if (userId === undefined || userId === null || userId === "") {
            navigate('/', {});
        } else {
            if (!userData.userId) {
                getUser(userId).then(res => {
                    setUserData(res.data);
                    setIsError(false);
                    setErrorMessage('');
                    setIsHomeLoaded(true);
                }).catch(res => {
                    setIsHomeLoaded(false);
                    setIsError(true);
                    setErrorMessage(res.message);
                })
            }
        }
    }, [navigate, userData.userId]);

    return (
        <MainLayout>
            <HomeLayout>
                {isHomeLoaded ? (
                    <HomeBody>
                        <Title>{FW.F_W_HOME_T}</Title>
                        <HomeRow>
                            <HomeColTitle>{FW.F_W_SYS_N}</HomeColTitle>
                            <HomeColText>{userData.userId}</HomeColText>
                        </HomeRow>
                        <HomeRow>
                            <HomeColTitle>{FW.F_W_SYS_UN}</HomeColTitle>
                            <HomeColText>{userData.userName}</HomeColText>
                        </HomeRow>
                        <HomeRow>
                            <HomeColTitle>{FW.F_W_SYS_P}</HomeColTitle>
                            <HomeColText></HomeColText>
                            {/* <HomeButton>{FW.F_W_HOME_CHANGE} </HomeButton> */}
                        </HomeRow>
                        <HomeRow>
                            <HomeColTitle>{FW.F_W_SYS_G}</HomeColTitle>
                            <HomeColText>{genderVal(userData.gender)}</HomeColText>
                        </HomeRow>
                        <HomeRow>
                            <HomeColTitle>{FW.F_W_SYS_B}</HomeColTitle>
                            <div className={"date"}>
                                <HomeColText>
                                    {formatDate(userData.birthday)}
                                </HomeColText>
                            </div>
                        </HomeRow>
                        <HomeRow>
                            <HomeColTitle>{FW.F_W_SYS_TEL}</HomeColTitle>
                            <HomeColText>{userData.tel}</HomeColText>
                        </HomeRow>
                        <HomeRow>
                            <HomeColTitle>{FW.F_W_SYS_E}</HomeColTitle>
                            <HomeColText>{userData.email}</HomeColText>
                        </HomeRow>
                    </HomeBody>
                ) : (
                    <>{isError && <p>{errorMessage}</p>}</>
                )}
            </HomeLayout>
        </MainLayout>
    );
}

export default Home;