import React, { useEffect,useState } from 'react';
import { FaUser } from 'react-icons/fa';
import { SearchLayout, SearchBody, Title, SearchRow, SearchColText } from './Search.style.js';
import FW from '../../config/word.json';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../components/Layouts/Main/MainLayout.index.js';
import { tasks } from '../../store/home/homeReducer.js';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import { SmallButton } from '../../styles/common';



function Search(props) {

    const navigate = useNavigate();
    // ログイン状態ではない場合、ログイン画面へ遷移する
    useEffect(() => {
        var userId = sessionStorage.getItem('userId');
        if (userId === undefined || userId === null || userId === "") {
            navigate('/', {});
        }
    });
    const HumanIcon = () => {
        return <FaUser />;
    }

    const [results, setResults] = useState([]);

    const handleSearch = (query) => {
        // ここで検索処理を行い、結果を設定する
        setResults([...results, `Result for: ${query}`]);
    };

    return (
        <MainLayout>
            <SearchLayout>
                <SearchBody>
                    <Title>{FW.F_W_HOME_Search}</Title>
                    <SearchForm onSubmit={handleSearch} />
                    <SearchResults results={results} />
                    {tasks.map((item) => {
                        return (
                            <SearchRow key={item.key}>
                                <SearchColText><HumanIcon />{item.name}{item.dantai}</SearchColText>
                                <SearchColText><HumanIcon />{item.name}{item.dantai}</SearchColText>
                                <SearchColText><HumanIcon />{item.name}{item.dantai}</SearchColText>
                                <SmallButton>❤️お気に入り </SmallButton>
                            </SearchRow>
                        );
                    })}
                </SearchBody>
            </SearchLayout>
        </MainLayout>
    );
}

export default Search;