import styled from '@emotion/styled';
import { Row } from 'react-bootstrap';
import { LIGHT_BLUE, GREY,APP_TEMA,WHITE,BEI_JYO } from '../../styles/color';

export const Title = styled.h2`
  margin-left: 20px;
  margin-right: 20px;
  color: ${LIGHT_BLUE};
  text-align: left;
`;
export const ApprovalListLayout = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 10px;
  width: 100%;
  text-align: center;
  padding-bottom: 100px;
`;

export const ApprovalListBody = styled.div`
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
`;

export const ApprovalListRow = styled(Row)`
  padding-top: 1px;
  display: flex;
  align-items: center;
  margin: 1px 1px;
  border-bottom: 1px solid ${GREY};
  background-color: ${BEI_JYO};
`;

export const ApprovalListRowKu = styled(Row)`
  padding-top: 5px;
  display: flex;
  align-items: center;
  margin: 1px 1px;
`;

export const ApprovalListRowOfHead = styled(Row)`
  padding-top: 1px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  margin: 0px 1px;
  border-bottom: 1px solid ${GREY};
  background-color: ${APP_TEMA};
  color: ${WHITE};
`;


export const ApprovalListColText = styled.p`
  width: 100px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 10px;
  line-height: 22px;
  font-size: 15px;
  letter-spacing: 0.9px;
  margin-top: 2px;
  margin-bottom: 2px;
`;

export const ApprovalListColTextOfHead = styled.p`
  width: 100px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 10px;
  line-height: 22px;
  font-size: 15px;
  letter-spacing: 0.9px;
  margin-top: 2px;
  margin-bottom: 2px;
  color: ${WHITE};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* 要素を右寄せ */
  gap: 10px; /* ボタン間の隙間 */
`;

export const linkStyle = {
  textDecoration: 'none',
  color: 'inherit' // リンクの色を継承する
};