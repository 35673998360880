import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { HeaderLayout, MenuLink } from './PCHeader.style';
import { SideBarItems } from '../../MainSideBarItems';
import FW from '../../../../../config/word.json'

const CustomMenuLink = ({ title, to, label }) => {
    const url = useLocation().pathname;

    // ログアウトのボタンを押下時にセッションストレージをクリアする
    const onClickMenuItem = (toLabel) => () => {
        if (toLabel === 'logout') {
            sessionStorage.clear();
        }
    };

    const active = url.includes(to) && to !== '/' ? 'active' : '';

    return (
        <>
            <MenuLink to={to} onClick={onClickMenuItem(label)} active={active}>
                {title}
            </MenuLink>
        </>
    );
};

const CommonMenu = memo(() => {
    return (
        <>
            {SideBarItems.map((value) => {
                return (
                    <CustomMenuLink
                        key={value.title}
                        title={value.title}
                        to={value.path}
                        label={value.label}
                    />
                );
            })}
        </>
    );
});

/**
 * PC版とタブレット版のコンポーネント
 * @returns PC版とタブレット版のコンポーネント
 */
const PCHeader = () => {
    let showflg=true;
    const url = useLocation().pathname;
    // お知らせ画面である場合、メニューバーが不要
    if(url.includes('notice')){
        showflg=false;
    }

    return (
        <>
            <HeaderLayout>
                <h2>{FW.F_W_LOGIN_T1}</h2>
                <div>
                {showflg?<CommonMenu />:<></>}
                </div>
            </HeaderLayout>
        </>
    );
};

export default memo(PCHeader);
