import styled from '@emotion/styled';
import { Row } from 'react-bootstrap';
import { LIGHT_BLUE, GREY,APP_TEMA } from '../../styles/color';

export const Title = styled.h2`
  margin-left: 20px;
  margin-right: 20px;
  color: ${LIGHT_BLUE};
  text-align: left;
`;
export const TaskLayout = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  border-radius: 10px;
  width: 100%;
  text-align: center;
  padding-bottom: 100px;
`;

export const TaskBody = styled.div`
  max-width: 720px;
  margin-right: auto;
  margin-left: auto;
`;

export const TaskRow = styled(Row)`
  padding-top: 20px;
  display: flex;
  align-items: center;
  margin: 0px 20px;
  border-bottom: 1px solid ${GREY};
`;

export const TaskRowOfHead = styled(Row)`
  padding-top: 20px;
  display: flex;
  align-items: center;
  margin: 0px 20px;
  border-bottom: 1px solid ${APP_TEMA};
  background-color: ${APP_TEMA};
`;

export const TaskColText = styled.p`
  box-sizing: border-box;
  text-align: left;
  padding-left: 10px;
  line-height: 22px;
  font-size: 18px;
  letter-spacing: 0.9px;
  margin-top: 10px;
  margin-bottom: 5px;
`;