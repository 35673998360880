import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { LIGHT_BLUE, BLACK, WHITE, LIGHT_GRAY } from '../../../../../styles/color';

export const HeaderLayout = styled.div`
  text-align: center;
  padding: 0px 20px;
  border-bottom: 1px solid ${LIGHT_GRAY};
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  padding-left: 40px;
  padding-right: 65px;
  // margin-right: auto;
  // margin-left: auto;

  > h2 {
    color: ${LIGHT_BLUE};
  }

  & > div:nth-of-type(1) {
    display: flex;
    align-items: baseline;
    justify-content: end;
    gap: 55px;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${BLACK};
    height: 40px;
    text-decoration: none;
    border-radius: 30px;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const MenuLink = styled(Link)`
  // background-color: ${LIGHT_BLUE} !important;
  background-color: ${props => (props.active === 'active' ? `${LIGHT_BLUE} !important` : ``)};
  color: ${props => (props.active === 'active' ? `${WHITE} !important` : ``)};
  padding: 8px 25px 8px 25px;
  margin: 0 -25px 0 -25px;
`;